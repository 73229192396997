.sectionTitle {
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.sectionSubtitle {
  font-size: 16px;
}

.viewAllButton {
  font-weight: 500;
  color: #051435;
  text-decoration: none;
  background-color: #eceef1;
  padding: 8px 16px;
  border-radius: 4px;
}
.viewAllButton:hover {
  text-decoration: underline;
}

.bookCard {
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  padding: 1rem;
  height: 180px;
  display: flex;
  align-items: center;
  position: relative;
}

.yearBadge {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: #051435;
  color: #fff;
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  border-radius: 5px;
}

.imageWrapper {
  margin-right: 10px;
}

.bookImage {
  width: 70px;
  height: 90px;
  object-fit: cover;
  border-radius: 4px;
  transition: transform 0.3s ease, z-index 0.3s ease;
}

.bookImage:hover {
  transform: scale(1.5);
}

.authorLink {
  color: #051435;
  font-weight: 500;
  text-decoration: none;
}
.authorLink:hover {
  text-decoration: underline;
}

.bookTitle {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
}

.authors {
  margin: 0;
  font-size: 13px;
  color: #666;
}

.hidden {
  display: none;
}

.arrowStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  color: grey;
  cursor: pointer;
  position: absolute;
  border: 1px solid #dddddd;
  z-index: 2;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.hidden {
  display: none;
}

.nextArrow {
  right: -20px;
  top: 40%;
}

.prevArrow {
  left: -20px;
  top: 40%;
}
