/* Projekti.module.css */
.paperCard {
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 150px;
  /* etc. */
}

.projectTitle {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  min-height: 50px;
}

.yearRange {
  margin: 0;
  font-size: 14px;
}

.readMoreButton {
  font-size: 12px;
  cursor: pointer;
  color: #007bff;
  border: 1px solid #007bff;
  background: none;
  padding: 3px 8px;
  border-radius: 5px;
  margin-top: 10px;
  width: fit-content;
  margin-top: auto;
}

.modalTitle {
  font-size: 18px;
  font-weight: 600;
}

.links {
  color: #051435;
  font-weight: 500;
  text-decoration: none;
}

.links:hover {
  text-decoration: underline;
}
