/* ProjektiList.module.css */

/* Outer container for the project cards */
.radoviContainer {
  margin-top: 1rem;
  max-height: 600px;
  overflow: auto;
}

/* Individual card style */
.projectCard {
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #fff;
  padding: 1rem;
  position: relative;
}

/* The top row: Title + optional external link icon, if you want one */
.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projectTitle {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 10px;
}

/* Row with year and funder */
.yearFunderRow {
  margin: 0.25rem 0;
  font-size: 14px;
}
.yearFunderRow .text-muted {
  color: #888;
  font-size: 13px;
}

.readMoreButton {
  font-size: 12px;
  cursor: pointer;
  color: #007bff;
  border: 1px solid #007bff;
  background: none;
  padding: 3px 5px;
  border-radius: 5px;
  margin-top: 10px;
}
.readMoreButton:hover {
  color: #fff;
  background-color: #007bff;
}

.modalTitle {
  font-weight: 600;
  font-size: 18px;
}

.modalSection {
  margin-bottom: 1rem;
  max-height: 300px;
  overflow: auto;
}

.managerRow {
  font-size: 16px;
}
.membersRow {
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.descriptionText {
  margin-top: 0.5rem;
  font-size: 14px;
  line-height: 1.4;
}
.links {
  color: #051435;
  font-weight: 500;
  text-decoration: none;
}

.links:hover {
  text-decoration: underline;
}
